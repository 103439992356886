import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Loader, Modal, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";
import format_number from "../../Helpers/number_formatter";
import { OrderStatus } from "../../Constants/types";
import { orderState } from "../../Atoms/odrder.atom";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
export default function Orders(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [show, setshow] = useState(0);
  const [show2, setshow2] = useState(0);
  const [model, setmodel] = useRecoilState(orderState);
  const [deliverycompanies, setdeliverycompanies] = useState([]);
  const [products, setproducts] = useState([]);
  const [deliveryModel, setDeliveryModel] = useState({
    remark: "",
    orderId: 0,
    beginProcessDate: new Date(),
    endDate: new Date(),
    deliveryCompanyId: 0,
    status: 1,
  });
  const [packs, setpacks] = useState([]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel({});
    setError("");
    setDeliveryModel({
      remark: "",
      orderId: 0,
      beginProcessDate: new Date(),
      endDate: new Date(),
      deliveryCompanyId: 0,
      status: 1,
    });
  };
  // API CALLS

  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/myOrders", filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
        let _prods = [];
        res.data.data.map((el) =>
          el.items.map((item) => {
            if (!_prods.find((p) => p.id == item.productId))
              _prods.push(item.product);
          })
        );
        console.log(_prods);
        setproducts(_prods);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = { ...model };
    m.items = m.items.map((el) => {
      let _el = { ...el };
      delete _el.id;
      delete _el.product;
      _el.quantity = parseInt(_el.quantity);

      return _el;
    });
    // m.totalPrice=m.items.reduce((a=0,b)=>{
    //   return a+(b.quantity * )
    // })
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order)
        .update(model.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setdeliverycompanies(res.data);
      })
      .catch((e) => console.log(e.Message));
  }, []);
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Statut: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(OrderStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>

      <Grid
        actions={[
          {
            label: "annuler",
            action: (dataKey) => {
              APi.createAPIEndpoint(
                ENDPOINTS.Order + "/changeStatus/" + dataKey + "/5"
              )
                .update3()
                .then((res) => fetch());
            },
            render: (v) => (
              <button
                style={{
                  color: "rgba(67,55,160,1)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(67,55,160,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
        ]}
        // deleteAction={deleteAction}
        actionKey="id"
        //   noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "reference",
    name: "Réf",
    render: (v) => <strong style={{ color: "indigo" }}>{v}</strong>,
  },

  {
    value: "orderDate",
    name: "Date",
    render: (v) => <strong>{moment(v).format("L")}</strong>,
  },

  {
    value: "totalPrice",
    name: "Prix Total",
    render: (v) => <b style={{ color: "green" }}>{v && format_number(v)}</b>,
  },
  // {
  //   value: "totalDeliveryCost",
  //   name: "Cout livraison Total",
  //   render: (v) => <b style={{ color: "green" }}>{v && format_number(v)}</b>,
  // },
  {
    value: "status",
    name: "Statut",
    render: (v) => (
      <Tag
        color={
          v == 1
            ? "blue"
            : v == 2
            ? "yellow"
            : v == 3
            ? "violet"
            : v == 4
            ? "green"
            : "red"
        }
      >
        {v && OrderStatus.find((el) => el.value == v).label}
      </Tag>
    ),
  },
];
