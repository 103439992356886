export default class OrderModel {
  customer = { fullName: "", email: "", phoneNumber: "", address: "" };

  items = []; //OrderItem
  totalPrice = 0;
  orderDate = new Date();
  TotalDeliveryCost = 0;
  // packagingJSON = "";
  constructor(customer, items, totalPrice, orderDate) {
    this.customer = customer;
    this.items = items;
    this.totalPrice = totalPrice;
    this.orderDate = orderDate;
    // this.packagingJSON = packagingJSON;
  }
}
export class OrderItem {
  quantity = 0;
  unitPrice = 0;
  colorAndTypeId = 0;
  constructor(quantity, unitPrice, colorAndTypeId) {
    this.colorAndTypeId = colorAndTypeId;
    this.quantity = quantity;
    this.unitPrice = unitPrice;
  }
}
