export const clientTypes = [
  { label: "B2B", value: 1 },
  { label: "B2C", value: 2 },
];
// _________________________________________________________________________

export const paymentType = [
  { label: "Chéque", value: 1 },
  { label: "Espèces ", value: 2 },
  { label: "Solde", value: 3 },
  // { label: "CCA", value: 4 },
  // { label: "Versement", value: 5 }, // chechout
  { label: "Virement Bancaire", value: 4 },
];

// Inprocess = 1,

// ReadyForDispatch, // to delivery process

// Dispatched,

// Delivered,

// canceled, // by the customer

// Rejected,
export const PaymentEventType = [
  { label: "Récompense", value: 1 },
  { label: "Sortie", value: 2 },
];
// RECOVERY_CLIENT = 1, GUARANTEE_CLIENT,
// PAYMENT_PROVIDER,
// GUARANTEE_PROVIDER,
export const OrderStatus = [
  { label: "En attente", value: 1 },
  { label: "Prét pour livraison", value: 2 },
  { label: "Encours de  livraison", value: 3 },
  { label: "Livré", value: 4 },
  { label: "Annulé", value: 5 },
  { label: "Refusé", value: 6 },
];

// public enum SaleStatus
//         {
//         [Description("Not paid")]
//         NotPaid,
//         [Description("Partial Paid")]
//         PartialPaid,
//         [Description("Pending")]
//         Pending,
//         [Description("Paid")]
//         Paid,
//         [Description("Refund")]
//         Refund,
//         [Description("Canceled")]
//         Canceled
//     }
// ---------------------------------------------------------------------------
export const dateFilter = [
  { label: "Aujourd'hui", value: 1 },
  { label: "Hier", value: 2 },
  { label: "Cette semaine", value: 3 },
  { label: "la semaine dernière", value: 4 },
  { label: "Ce mois", value: 5 },
  { label: "Le mois dernier", value: 6 },
  { label: "Cette année", value: 7 },
  { label: "Personnalisé", value: 8 },
  { label: "Jour", value: 9 },
];

//-----------------------------------

export const serviceTypes = [
  { label: "Billetterie", value: 1 },
  { label: "Hôtel", value: 2 },
  { label: "Bateau", value: 3 },
  { label: "Autobus", value: 4 },
  { label: "Excursion", value: 5 },
  { label: "Voyage Organisé", value: 6 },
  { label: "Omrah", value: 7 },
  { label: "Autres", value: 8 },
];
// ticketing=1, hotel,ship,bus, excursion,
// trip,
// umrah, others

export const Gender = [
  { label: "Masculin", value: 1 },
  { label: "Féminin", value: 2 },
];
export const AgeType = [
  { label: "Adulte", value: 1 },
  { label: "Enfant", value: 2 },
];

export const roomTypes = [
  { label: "Chambre simple", value: "Chambre simple" },
  { label: "Chambre double", value: "Chambre double" },
  { label: "Chambre triple", value: "Chambre triple" },
  { label: "Chambre quadruple", value: "Chambre quadruple" },
  { label: "Chambre Familiale", value: "Chambre Familiale" },
  { label: "Suite", value: "Suite" },
];

//---------------------------------
export const dateTypes = [
  { label: "Aujourd'hui", value: 1 },
  { label: "Hier", value: 2 },
  // { label: "Cette semaine", value: 3 },
  // { label: "la semaine dernière", value: 4 },
  { label: "Ce mois", value: 3 },
  { label: "Le mois dernier", value: 4 },
  // { label: "Cette année", value: 7 },
  { label: "Personnalisé", value: 5 },
  { label: "Jour", value: 6 },
];
