import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Content,
  Dropdown,
  Sidenav,
  Nav,
  Navbar,
  Sidebar,
} from "rsuite";
import { Dashboard } from "@rsuite/icons";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import DeviceOtherIcon from "@rsuite/icons/DeviceOther";
import BranchIcon from "@rsuite/icons/Branch";
import MemberIcon from "@rsuite/icons/Member";
import { Speaker, Media } from "@rsuite/icons";
import PeoplesIcon from "@rsuite/icons/Peoples";
import PageNextIcon from "@rsuite/icons/PageNext";
import GearIcon from "@rsuite/icons/Gear";
import { useLocation } from "react-router-dom";

import CouponIcon from "@rsuite/icons/Coupon";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { BsGraphUp, BsMenuButton } from "react-icons/bs";
import "./App.scss";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Link,
  Redirect,
} from "react-router-dom";

import Login from "./Screens/Auth/login";
import { useRecoilState } from "recoil";
import { isLogged } from "./Atoms/auth.atom";

import { PageTitle } from "./Atoms/page.title.atom";

import { APi } from "./Api";

import "../node_modules/react-vis/dist/style.css";

import Products from "./Screens/cms/products";
import Orders from "./Screens/orders";
import axios from "axios";
const iconStyles = {
  width: 56,
  height: 56,
  padding: 18,
  lineHeight: "56px",
  textAlign: "center",
};
const access =
  "EAANYBzomEfEBAGZCQLoqaB50xG2fKlZA2jXZAI0iYUWLPxNqU8UfEXE0eZCH5zGt8NJI8eYGkM9o8KlyoUob3CIZAhlOj15DE2jarAiDo1C6HVREFdZClbNt1KLN0ZAEnixmysDiNqN3DZBftveZAVHqBY68nMB2p3tzJDTC1Y8yxI2yGwrno2XiBbX0cmf5tIZAoSQauwt7WrSLNHXq6ySlc8uubK7wCd5WMZD";

const App = (props) => {
  const [expand, setExpand] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [active, setactive] = useState(1);
  const [logged, setlogged] = useRecoilState(isLogged);
  const [pageTitle, setpageTitle] = useState("Produits");
  const location = useLocation();
  // const getToken = () => {
  //   axios
  //     .get(
  //       "https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=1180402002867964&client_secret=7a8648b00d0ac613906f44bcdecc6c21&fb_exchange_token=EAAQxkcIStvwBAFT52IoSxZAOOXYRnvXiu6KQoUzRmAzKNRlMfHNMuMxB4OTE8lceFku4YeJZCeftx7qZAK9b7wNrXuD74uIetMZBj8UMiUNE1H3lcZBmvJcNNh1U7yZBMtbmTtZCCnpyBCk1ZC3iqE7ZAsE8gJyn2rZCXRZCnZCu1UF0nrEr1gX92goTh4Yp1LeuSfppJ6nkxd0yZAn7a1HIplXnL"
  //     )
  //     .then((res) =>
  //       localStorage.setItem("fb_access_token", res.data.access_token)
  //     );
  // };

  useEffect(() => {
    // getToken();
    // console.log(accessToken);
    // FB.setAccessToken(accessToken);
    // FB.api("", function (res) {
    //   if (!res || res.error) {
    //     console.log(!res ? "error occurred" : res.error);
    //     return;
    //   }
    //   console.log(res);
    //   // console.log(res.name);
    // });
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
    }
    setlogged(log);
    console.log(log);
    setTimeout(() => {
      setloaded(true);
      setpageTitle(routes[location.pathname]);
    }, 1000);
  }, [logged]);
  useEffect(() => {
    setExpand(false);
  }, [pageTitle]);
  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  return (
    <div className="app">
      <Container>
        {logged && (
          <Sidebar className={"app-sidebar " + (expand ? "show" : "")}>
            <Sidenav.Header>
              <div className="app-sidebar-header">
                <div>
                  <BsGraphUp style={{ fontSize: 20 }} />
                  <b style={{ marginLeft: 12, fontSize: "large" }}>
                    {" "}
                    Le royaume du bois
                  </b>{" "}
                </div>{" "}
                <button
                  className="close_menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  x
                </button>
              </div>
            </Sidenav.Header>
            <Sidenav defaultOpenKeys={["3"]} appearance="subtle">
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    onClick={() => {
                      // setExpand(false);
                      setactive("2");
                      setpageTitle("Commandes");
                    }}
                    active={active == "2"}
                    eventKey="2"
                    icon={<CreditCardPlusIcon size="3em" />}
                  >
                    <Link className={"side_link "} to="/orders">
                      Commandes
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    eventKey="6-1"
                    onClick={() => {
                      // setExpand(false);
                      setactive("6-1");
                      setpageTitle("Produits");
                    }}
                    icon={<DeviceOtherIcon size="3em" />}
                    active={active == "6-1"}
                  >
                    <Link className={"side_link "} to="/products">
                      Produits
                    </Link>
                  </Nav.Item>

                  {/* </Dropdown> */}

                  {/* <h6
                    style={{
                      border: "1px solid #eee",
                      background: "rgb(252,179,34,0.2)",
                      borderRight: "0 none",
                    }}
                    className="p-10"
                  >
                    Pour le SUPER ADMIN
                  </h6> */}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
          </Sidebar>
        )}

        <Container className={"hole-container"}>
          {logged && (
            <Header className="page-header">
              <h4>{pageTitle}</h4>

              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <button
                  className="menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  <BsMenuButton />
                </button>
                <Nav>
                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon size="3em" />}
                    renderTitle={(children) => {
                      return <GearIcon style={iconStyles} />;
                    }}
                  >
                    {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}

          <Content className={"app-content " + (!logged ? "" : "logged")}>
            <Switch>
              <Route
                path="/products"
                render={(props) => AuthGard(<Products {...props} />)}
              />
             
              <Route
                path="/orders"
                render={(props) => AuthGard(<Orders {...props} />)}
              />
             
              <Route
                path="/*"
                render={(props) => AuthGard(<Products {...props} />)}
              />
            </Switch>
          </Content>
        </Container>
      </Container>
    </div>
  );
};
const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? (
            <PagePreviousIcon size="3em" />
          ) : (
            <PageNextIcon size="3em" />
          )}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
export default App;

const routes = {
  "/tickets": "Tickets",
  "/tiers/clients": "Clients",
  "/checks": " Gestion des chéques",
  "/treasory/banks": "Banques",
  "/treasory/checkouts": "Caisses",
  "/invoices": "Factures",
  "/invoices/settings": "Paramétrage Facture",
};
