import axios from "axios";
// import { htmlToText } from "html-to-text";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Modal } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../../Api/";
import { createAPIEndpoint } from "../../../Api/authenticated.requests";
import { ENDPOINTS } from "../../../Api/enpoints";
import Filter from "../../../Components/Common/Filter";
import { BASE_URL } from "../../../Config/api.config";
import { NumericFormat } from "react-number-format";
import Responsive from "../../../Components/Responsive";
import ModalBody from "rsuite/esm/Modal/ModalBody";
import ModalFooter from "rsuite/esm/Modal/ModalFooter";
import ModalComponent from "../../../Components/galleryMobile/components/Modal";
import OrderModel, { OrderItem } from "../../../Models/OrderModel";
import { userState } from "../../../Atoms/user.atom";

export default function Products(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [pDTypes, setpDTypes] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({ q: "", page: 1, take: 20 });
  const [childAges, setchildAges] = useState([]);
  const [user, setuser] = useRecoilState(userState);

  // --- add edit model ---
  // ATOMS
  // HELPERS

  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Product + "/GetColotypes", filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((e) => console.log(e.Message));
  };
  const fetchPDTypes = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryType, {})
      .fetchAll()
      .then((res) => {
        setpDTypes(res.data);
      })
      .catch((e) => console.log(e.Message));
  };

  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchPDTypes();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        <div className="p-10">
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </div>
      </Filter>

      {data.map((item, i) => (
        <Responsive key={i} l={3} m={6} xl={2} className="p-10">
          <ProductCard key={item.slug} item={item} />
        </Responsive>
      ))}

      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
    </div>
  );
}

const ProductCard = ({ item }) => {
  const [selected, setselected] = useState([0]);
  const [imgSelected, setImgSelected] = useState([]);

  const [open, setopen] = useState(false);
  const [p, setp] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [modal, setmodal] = useState(false);
  useEffect(() => {
    if (item) {
      createAPIEndpoint(ENDPOINTS.Product + "/fetchBySlug")
        .fetchById(item.slug)
        .then((res) => {
          setp(res.data);
          setImgSelected(
            res.data.colorAndTypes
              ? res.data.colorAndTypes.map((el) => {
                  return 0;
                })
              : []
          );
          setselected(
            res.data.colorAndTypes
              ? res.data.colorAndTypes.map((el) => {
                  return 0;
                })
              : []
          );
        });
    }
  }, [item]);
  useEffect(() => {
    console.log(selected);
  }, [selected]);

  const confirm = () => {
    let m = new OrderModel(
      {},
      selected
        .map((item, i) => {
          if (!item) return false;
          let _it = {
            ...new OrderItem(
              item,
              p.colorAndTypes[i].grosPrice
                ? p.colorAndTypes[i].grosPrice
                : p.colorAndTypes[i].price *
                  (p.promotion
                    ? 1 - p.promotion.discount / 100
                    : 1 - p.colorAndTypes[i].discount / 100),

              p.colorAndTypes[i].id
            ),
          };
          _it.productId = p.id;
          return _it;
        })
        .filter((el) => el),
      selected
        .map((item, i) => {
          if (!item) return false;
          let _it = {
            ...new OrderItem(
              item,
              p.colorAndTypes[i].grosPrice
                ? p.colorAndTypes[i].grosPrice
                : p.colorAndTypes[i].price *
                  (p.promotion
                    ? 1 - p.promotion.discount / 100
                    : 1 - p.colorAndTypes[i].discount / 100),

              p.colorAndTypes[i].id
            ),
          };
          _it.productId = p.id;
          return _it;
        })
        .filter((el) => el)
        .reduce((val, ite) => val + ite.unitPrice * ite.quantity, 0),
      new Date()

      // JSON.stringify(packs)
    );
    m.isGros = true;
    setLoadingConfirm(true);
    console.log(m);
    createAPIEndpoint(ENDPOINTS.Order + "/Create2")
      .create(m)
      .then((res) => {
        setselected([]);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Élément a été bien enregistré !",
          showConfirmButton: false,
          timer: 1500,
        });
        setopen(false);
      })
      .catch((e) => setLoadingConfirm(false));
  };
  return (
    <div
      style={{ background: "#fff", borderRadius: "8px", overflow: "hidden" }}
    >
      <div className="overflow-hidden cursor-default rounded-xl relative group">
        <div
          style={{
            height: "160px",
            width: "100%",
            display: "block",
          }}
          className=" relative"
        >
          <img
            alt=""
            src={BASE_URL + "Uploads/" + item.defaultPicture.path}
            layout="fill" // required
            objectFit="cover" // change to suit your needs
            className="rounded-b-lg shadow-2xl bg-orange-100 " // just an example
            style={{ height: "160px", width: "100%" }}
          />
        </div>
      </div>
      <div className="p-10">
        <p
          onClick={() => setopen((prev) => !prev)}
          //  onClick={() => Router.push("/product/" + item.slug)}
          style={{ fontWeight: "bold", cursor: "pointer" }}
        >
          {item.name_fr}
        </p>

        <p className="text-xs my-2 text-gray-400">{item.color}</p>

        <div>
          <span
            style={{ color: "#777", fontWeight: "lighter", marginRight: "5px" }}
          >
            {" "}
            à partir de
          </span>
          <span style={{ fontWeight: "bold" }}>
            {(
              (item.colorAndTypes &&
                item.colorAndTypes.length &&
                [...item.colorAndTypes].sort((a, b) => a.price - b.price)[0]
                  .price) ||
              0
            ).toFixed(3)}{" "}
            {" TND "}
          </span>
        </div>
        <div>
          {item.productTags &&
            item.productTags.map((t) => (
              <a href="#" style={{ paddingRight: "5px" }}>
                #{t.tag.name}
              </a>
            ))}
        </div>
      </div>
      <Modal size="md" open={open} onClose={() => setopen(false)}>
        {p && (
          <ModalBody>
            <p
              onClick={() => setopen((prev) => !prev)}
              //  onClick={() => Router.push("/product/" + item.slug)}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                position: "relative",
              }}
            >
              {item.name_fr}
              {item.promotion ? (
                <span
                  style={{
                    borderRadius: "4px",
                    background: "#e33",
                    color: "#fff",
                  }}
                  className="absolute text-xs left-1 top-3 px-2 py-2"
                >
                  EN PROMO{" "}
                  <span className="ml-2"> -{item.promotion.discount}%</span>
                </span>
              ) : (
                ""
              )}
            </p>
            {/* <div style={{ textAlign: "center" }}>
            
            </div> */}
            <hr></hr>
            <div>
              {p.colorAndTypes &&
                p.colorAndTypes.map((c, i) => (
                  <Responsive xl={4} l={6} className="p-5">
                    <div
                      style={{
                        border: "1px dashed #aaa",
                        borderRadius: "10px",
                      }}
                      className="p-10"
                    >
                      <div>
                        <img
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: "240px",
                            borderRadius: "4px",
                            marginBottom: "5px",
                            border: "1px solid #222",
                          }}
                          src={
                            BASE_URL +
                            "Uploads/" +
                            (c.medias && c.medias[imgSelected[i]]
                              ? c.medias[imgSelected[i]].media.path
                              : p.defaultPicture.path)
                          }
                        />
                      </div>
                      <div>
                        {c.medias &&
                          c.medias.map((m, j) => (
                            <img
                              onClick={() =>
                                setImgSelected((prev) => {
                                  let arr = [...prev];
                                  arr[i] = j;
                                  return arr;
                                })
                              }
                              alt=""
                              style={{
                                width: "60px",
                                objectFit: "cover",
                                height: "60px",
                                borderRadius: "4px",
                                marginRight: "5px",
                                cursor: "pointer",
                                border: "1px solid #222",
                              }}
                              src={BASE_URL + "Uploads/" + m.media.path}
                            />
                          ))}
                      </div>
                      <h6>{c.color_name_fr}</h6>
                      <span>{c.reference}</span>
                      <br></br>{" "}
                      <strong>
                        {(c.grosPrice
                          ? c.grosPrice
                          : c.price *
                            (p.promotion
                              ? 1 - p.promotion.discount / 100
                              : 1 - c.discount / 100)
                        ).toFixed(3)}{" "}
                        {" TND"}
                      </strong>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          style={{
                            padding: "7px 10px",
                            border: "1px solid #222",
                            margin: "0 4px",
                          }}
                          onClick={() =>
                            setselected((prev) =>
                              prev.map((k, j) => (j == i && k > 0 ? k - 1 : k))
                            )
                          }
                        >
                          -
                        </button>
                        {selected[i]}{" "}
                        <button
                          style={{
                            padding: "7px 10px",
                            border: "1px solid #222",
                            margin: "0 4px",
                          }}
                          onClick={() =>
                            setselected((prev) =>
                              prev.map((k, j) => (j == i ? k + 1 : k))
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </Responsive>
                ))}
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <button
            onClick={confirm}
            disabled={selected.every((el) => !el)}
            style={{
              color: "#fff",
              background: "rgb(0,169,141)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {loadingConfirm ? "Loading ..." : "Commander"}
          </button>
          <button onClick={() => setopen(false)}>fermer</button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
