export const ENDPOINTS = {
  Accounts: "Accounts",
  Media: "Media",
  Category: "Category",
  Auth: "Auth",

  Role: "Role",
  Order: "Order",

  Tag: "Tag",

  File: "file",

  Common: "Common",
  Product: "Product",
  Store: "Store",
  Content: "Content",
  Customer: "Customer",
  Promotion: "Promotion",
  PromoCode: "PromoCode",
  WoodType: "WoodType",
  DeliveryCompany: "DeliveryCompany",
  Delivery: "Delivery",
  DeliveryType: "DeliveryType",
  Announcement: "Announcement",
};
